import React, { useState, useEffect } from "react"
import Header from "../components/header"
import styles from "../styles/Contacto.module.css"

const { db } = require("../firebaseConfig")
const { collection, getDocs, addDoc } = require("firebase/firestore")

const Contacto = () => {
  const [clientes, setClientes] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [newContact, setNewContact] = useState({
    nombre: "",
    email: "",
    telefono: "",
  })
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    const fetchClientes = async () => {
      const clientesCollection = collection(db, "Clientes")
      const clientesSnapshot = await getDocs(clientesCollection)
      const clientesList = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      setClientes(clientesList)
    }

    fetchClientes()
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewContact({
      ...newContact,
      [name]: value,
    })
  }

  const handleAddContact = async (e) => {
    e.preventDefault()
    const clientesCollection = collection(db, "Clientes")
    const newDoc = await addDoc(clientesCollection, newContact)
    setClientes([...clientes, { id: newDoc.id, ...newContact }])
    setShowPopup(false)
    setNewContact({ nombre: "", email: "", telefono: "" })
  }

  const filteredClientes = clientes.filter(
    (cliente) =>
      (cliente.nombre &&
        cliente.nombre.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (cliente.email &&
        cliente.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (cliente.telefono &&
        cliente.telefono.toLowerCase().includes(searchQuery.toLowerCase()))
  )

  const openWhatsApp = (telefono) => {
    const message = encodeURIComponent("Hola, ¿cómo estás?")
    const url = `https://wa.me/${telefono}?text=${message}`
    window.open(url, "_blank")
  }

  return (
    <div className={styles["contacto-container"]}>
      <Header />
      <h1 className={styles["contacto-title"]}>Clientes</h1>
      <p className={styles["contacto-total"]}>
        Total de contactos: {clientes.length}
      </p>
      <input
        type="text"
        className={styles["search-bar"]}
        placeholder="Search contacts..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button
        className={styles["add-contact-button"]}
        onClick={() => setShowPopup(true)}
      >
        Add Contact
      </button>
      <ul className={styles["contacto-list"]}>
        {filteredClientes.map((cliente) => (
          <li key={cliente.id} className={styles["contacto-item"]}>
            <p>
              <strong>Name:</strong> {cliente.nombre}
            </p>
            <p>
              <strong>Email:</strong> {cliente.email}
            </p>
            <p>
              <strong>Phone:</strong> {cliente.telefono}
            </p>
            <button
              className={styles["whatsapp-button"]}
              onClick={() => openWhatsApp(cliente.telefono)}
            >
              WhatsApp
            </button>
          </li>
        ))}
      </ul>

      {showPopup && (
        <div className={styles["popup"]}>
          <div className={styles["popup-inner"]}>
            <h2>Add New Contact</h2>
            <form onSubmit={handleAddContact}>
              <label>
                Name:
                <input
                  type="text"
                  name="nombre"
                  value={newContact.nombre}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={newContact.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Phone:
                <input
                  type="tel"
                  name="telefono"
                  value={newContact.telefono}
                  onChange={handleInputChange}
                  required
                />
              </label>
              <button type="submit">Add Contact</button>
              <button type="button" onClick={() => setShowPopup(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default Contacto
