import "../styles/qrAbbasStyles.css"
const QRAbbas = () => {
  return (
    <div className="qr-wrapper">
      <h1>Abbas Stories QR</h1>
      <img src={"/qrAbbas.JPG"} alt={"qr-abbas"} className="" />
    </div>
  )
}

export default QRAbbas
