import React from "react"

function Underline({ color }) {
  return (
    <svg
      width="172"
      height="6"
      viewBox="0 0 172 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5C3.99194 4.46295 13.112 1.85755 18.9517 1.77772C24.7913 1.69789 30.3427 4.61536 36.0382 4.52101C41.7337 4.42667 48.0059 1.29873 53.1247 1.21164C58.2434 1.12455 61.4517 4.02025 66.7506 3.99848C72.0496 3.97671 78.8266 0.957631 84.9186 1.08101C91.0106 1.20438 97.8596 4.70245 103.303 4.73873C108.746 4.77502 111.558 1.34227 117.578 1.29873C123.598 1.25518 133.799 4.52101 139.422 4.47747C145.046 4.43392 146.055 1.34227 151.318 1.03746C156.581 0.732651 167.72 2.38008 171 2.6486"
        stroke={color || "#5079A9"}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Underline
