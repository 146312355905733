import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { motion } from "framer-motion"
import Header from "../components/header"
import VentaPopup from "../components/VentaPopup"
import * as XLSX from "xlsx"
import "../styles/pedidosStyles.css"
const { db } = require("../firebaseConfig")
const {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  runTransaction,
  where,
} = require("firebase/firestore")

const Pedidos = () => {
  const [ventas, setVentas] = useState([])
  const [loadingStates, setLoadingStates] = useState({})
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [totalVentas, setTotalVentas] = useState(0)
  const [selectedVenta, setSelectedVenta] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const location = useLocation()

  const fetchVentas = async (startDate, endDate) => {
    setIsLoading(true)
    const startOfDay = new Date(startDate.setHours(0, 0, 0, 0))
    const endOfDay = new Date(endDate.setHours(23, 59, 59, 999))
    const ventasCollectionRef = collection(db, "Ventas")
    const q = query(ventasCollectionRef, orderBy("fecha", "desc"))
    const ventasSnapshot = await getDocs(q)
    const ventasList = ventasSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter(
        (venta) =>
          venta.activo &&
          new Date(venta.fecha.toDate()) >= startOfDay &&
          new Date(venta.fecha.toDate()) <= endOfDay
      )
    console.log("Ventas recuperadas:", ventasList)

    setVentas(ventasList)
    setTotalVentas(ventasList.reduce((total, venta) => total + venta.total, 0))

    // Verificar si hay un ID de venta en la URL
    const searchParams = new URLSearchParams(location.search)
    const ventaId = searchParams.get("venta")
    if (ventaId) {
      const selected = ventasList.find((venta) => venta.id === ventaId)
      setSelectedVenta(selected)
    }

    setIsLoading(false)
    setIsEmpty(ventasList.length === 0)
  }

  useEffect(() => {
    fetchVentas(startDate, endDate)
  }, [startDate, endDate, location.search])

  const togglePaymentStatus = async (venta, event) => {
    event.stopPropagation() // Detener la propagación del evento

    setLoadingStates((prev) => ({ ...prev, [venta.id]: true }))

    let newStatus
    try {
      await runTransaction(db, async (transaction) => {
        const ventaRef = doc(db, "Ventas", venta.id)
        const ventaSnapshot = await transaction.get(ventaRef)
        if (!ventaSnapshot.exists) {
          throw new Error("El documento de venta no existe.")
        }

        const currentStatus = ventaSnapshot.data().estado
        newStatus = currentStatus === "Por pagar" ? "Pagado" : "Por pagar"

        const itemsUpdates = await Promise.all(
          venta.items.map(async (item) => {
            const inventoryQuery = query(
              collection(db, "Inventario"),
              where("_id", "==", item.id)
            )
            const querySnapshot = await getDocs(inventoryQuery)

            if (querySnapshot.empty) {
              throw new Error(
                `No se encontró el ítem con _id: ${item.id} en el inventario.`
              )
            }

            const inventoryDoc = querySnapshot.docs[0]
            const newQuantity =
              newStatus === "Pagado"
                ? inventoryDoc.data().stock - item.quantity
                : inventoryDoc.data().stock + item.quantity

            return {
              ref: inventoryDoc.ref,
              newQuantity: Math.max(newQuantity, 0),
            }
          })
        )

        itemsUpdates.forEach(({ ref, newQuantity }) => {
          transaction.update(ref, { stock: newQuantity })
        })

        transaction.update(ventaRef, { estado: newStatus })
      })

      setVentas(
        ventas.map((v) => (v.id === venta.id ? { ...v, estado: newStatus } : v))
      )
      setLoadingStates((prev) => {
        const newLoadingStates = { ...prev }
        delete newLoadingStates[venta.id]
        return newLoadingStates
      })
      console.log("Estado de la venta y stock de ítems actualizado con éxito.")
    } catch (error) {
      console.error(
        "Error al cambiar el estado de la venta y actualizar el inventario: ",
        error
      )
      setLoadingStates((prev) => {
        const newLoadingStates = { ...prev }
        delete newLoadingStates[venta.id]
        return newLoadingStates
      })
    }
  }

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(ventas)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Ventas")
    XLSX.writeFile(workbook, "ventas.xlsx")
  }

  const removeVenta = async (ventaId, event) => {
    event.stopPropagation() // Detener la propagación del evento

    try {
      await runTransaction(db, async (transaction) => {
        const ventaRef = doc(db, "Ventas", ventaId)
        const ventaSnapshot = await transaction.get(ventaRef)

        if (!ventaSnapshot.exists) {
          throw new Error("El documento de venta no existe.")
        }

        const itemsUpdates = await Promise.all(
          ventaSnapshot.data().items.map(async (item) => {
            const inventoryQuery = query(
              collection(db, "Inventario"),
              where("_id", "==", item.id)
            )
            const querySnapshot = await getDocs(inventoryQuery)

            if (querySnapshot.empty) {
              throw new Error(
                `No se encontró el ítem con _id: ${item.id} en el inventario.`
              )
            }

            const inventoryDoc = querySnapshot.docs[0]
            const newQuantity = inventoryDoc.data().stock + item.quantity

            return {
              ref: inventoryDoc.ref,
              newQuantity: Math.max(newQuantity, 0),
            }
          })
        )

        itemsUpdates.forEach(({ ref, newQuantity }) => {
          transaction.update(ref, { stock: newQuantity })
        })

        transaction.update(ventaRef, { activo: false })
      })

      setVentas((prevVentas) => prevVentas.filter((v) => v.id !== ventaId))
      console.log("Venta deshabilitada y stock actualizado con éxito.")
    } catch (error) {
      console.error(
        "Error al deshabilitar la venta y actualizar el inventario: ",
        error
      )
    }
  }

  const handlePaymentConfirmation = async (ventaId, amountPaid, change) => {
    console.log(
      "Pago confirmado para el pedido:",
      ventaId,
      ", Monto:",
      amountPaid,
      ", Cambio:",
      change
    )

    let newStatus = "Pagado"
    try {
      await runTransaction(db, async (transaction) => {
        const ventaRef = doc(db, "Ventas", ventaId)
        const ventaSnapshot = await transaction.get(ventaRef)
        if (!ventaSnapshot.exists) {
          throw new Error("El documento de venta no existe.")
        }

        // Actualiza el estado de la venta en la base de datos
        transaction.update(ventaRef, { estado: newStatus })

        // Actualiza el stock del inventario si es necesario
        const itemsUpdates = await Promise.all(
          ventaSnapshot.data().items.map(async (item) => {
            const inventoryQuery = query(
              collection(db, "Inventario"),
              where("_id", "==", item.id)
            )
            const querySnapshot = await getDocs(inventoryQuery)

            if (querySnapshot.empty) {
              throw new Error(
                `No se encontró el ítem con _id: ${item.id} en el inventario.`
              )
            }

            const inventoryDoc = querySnapshot.docs[0]
            const newQuantity = inventoryDoc.data().stock - item.quantity

            return {
              ref: inventoryDoc.ref,
              newQuantity: Math.max(newQuantity, 0),
            }
          })
        )

        itemsUpdates.forEach(({ ref, newQuantity }) => {
          transaction.update(ref, { stock: newQuantity })
        })
      })

      setVentas(
        ventas.map((v) => (v.id === ventaId ? { ...v, estado: newStatus } : v))
      )
      console.log("Estado de la venta y stock de ítems actualizado con éxito.")
    } catch (error) {
      console.error(
        "Error al cambiar el estado de la venta y actualizar el inventario: ",
        error
      )
    }

    // Volver a cargar las ventas después de confirmar el pago
    await fetchVentas(startDate, endDate)
    // Cerrar el popup
    setSelectedVenta(null)
  }

  useEffect(() => {
    console.log("selectedVenta changed:", selectedVenta)
  }, [selectedVenta])

  return (
    <div>
      <Header />
      <h1 className="titulo">Ventas</h1>
      <div className="date-filter">
        <label>
          Fecha de inicio:
          <input
            type="date"
            value={startDate.toISOString().substr(0, 10)}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />
        </label>
        <label>
          Fecha de fin:
          <input
            type="date"
            value={endDate.toISOString().substr(0, 10)}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
        </label>
      </div>
      <div className="summary">
        <p>
          Total Ventas:{" "}
          {totalVentas.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          })}
        </p>
        <p>Total de Filas: {ventas.length}</p>
      </div>
      <div className="wrapper">
        <button className="button-excel" onClick={downloadExcel}>
          Descargar Excel
        </button>
        {isLoading ? (
          <p>Cargando...</p>
        ) : isEmpty ? (
          <p>No hay ventas en este período.</p>
        ) : (
          <>
            <motion.table
              className="pedidos-table"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <thead>
                <tr>
                  <th>ID Pedido</th>
                  <th>Nombre del Cliente</th>
                  <th>Tipo de Pago</th>
                  <th>Productos</th>
                  <th>Total</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {ventas.map((venta) => (
                  <motion.tr
                    key={venta.id}
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: 50, opacity: 0 }}
                    onClick={() => setSelectedVenta(venta)}
                  >
                    <td>
                      <div
                        className={`status-indicator ${
                          venta.estado === "Pagado" ? "green" : "red"
                        }`}
                      ></div>
                      <span>{venta.id.slice(0, 4)}</span>
                    </td>
                    <td>{venta.customerName}</td>
                    <td>{venta.metodoPago}</td>
                    <td>
                      <button
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedVenta(venta)
                        }}
                      >
                        List
                      </button>
                    </td>
                    <td>
                      {venta.total
                        ? new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(venta.total)
                        : ""}
                    </td>
                    <td>
                      {loadingStates[venta.id] ? (
                        <div>...Cargando</div>
                      ) : (
                        <div
                          className={`status ${venta.estado
                            .toLowerCase()
                            .replace(/\s+/g, "_")}`}
                          onClick={(e) => togglePaymentStatus(venta, e)}
                        >
                          {venta.estado}
                        </div>
                      )}
                    </td>
                    <td>
                      <button onClick={(e) => removeVenta(venta.id, e)}>
                        Eliminar
                      </button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </motion.table>
          </>
        )}
      </div>
      {selectedVenta && (
        <VentaPopup
          venta={selectedVenta}
          onClose={() => setSelectedVenta(null)}
          onUpdatePayment={handlePaymentConfirmation}
        />
      )}
    </div>
  )
}

export default Pedidos
