// Import the functions you need from the SDKs you need using require
const { initializeApp } = require("firebase/app")
const { getFirestore } = require("firebase/firestore")
const { getAuth } = require("firebase/auth")

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwBrXmpGVsML4wflgWUIgCoYnxMzXkcOs",
  authDomain: "fiat-99743.firebaseapp.com",
  projectId: "fiat-99743",
  storageBucket: "fiat-99743.appspot.com",
  messagingSenderId: "514217743570",
  appId: "1:514217743570:web:60dfa8cd7065ba6cde890b",
  measurementId: "G-FYHP972P9Z",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const auth = getAuth(app)

// Use module.exports for CommonJS
module.exports = { db, auth }
