import InvoiceComponent from "../components/InvoiceComponent"

const Invoice = () => {
  return (
    <>
      <InvoiceComponent />
    </>
  )
}
export default Invoice
