import React from "react"

function CartSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <defs>
        <path id="cart-a" d="M0 0L1 3 11 3 12 0z"></path>
        <path
          id="cart-c"
          d="M4.019 7h11.807c.036 0 .072.002.108.006L18.208.659A.99.99 0 0119.137 0h2.874c.546 0 .988.448.988 1s-.442 1-.988 1H19.83l-4.063 11.34a.99.99 0 01-.93.66H4.955a.99.99 0 01-.938-.684l-2.965-9A.998.998 0 011.988 3h8.896c.546 0 .988.448.988 1s-.442 1-.988 1H3.36l.659 2zm.659 2l.988 3h8.479l1.074-3H4.678zM6.93 19a1.989 1.989 0 01-1.976-2c0-1.105.885-2 1.976-2 1.092 0 1.977.895 1.977 2s-.885 2-1.977 2zm6.92 0a1.989 1.989 0 01-1.978-2c0-1.105.885-2 1.977-2s1.977.895 1.977 2-.885 2-1.977 2z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0 3)">
        <g transform="translate(4 10)">
          <mask id="cart-b" fill="#fff">
            <use xlinkHref="#cart-a"></use>
          </mask>
          <use fill="#D8D8D8" xlinkHref="#cart-a"></use>
          <g fill="#FFA0A0" mask="url(#cart-b)">
            <path d="M0 0H24V24H0z" transform="translate(-4 -13)"></path>
          </g>
        </g>
        <mask id="cart-d" fill="#fff">
          <use xlinkHref="#cart-c"></use>
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#cart-c"></use>
        <g fill="#7600FF" mask="url(#cart-d)">
          <path d="M0 0H24V24H0z" transform="translate(0 -3)"></path>
        </g>
      </g>
    </svg>
  )
}

export default CartSvg
