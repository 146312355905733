import React, { useState, useEffect } from "react"
import Header from "../components/header"
import { useCart } from "../context/cartContext"
import { useNavigate } from "react-router-dom"
import "../styles/checkoutStyles.css"

const { db } = require("../firebaseConfig")
const {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} = require("firebase/firestore")

const Checkout = () => {
  const [customerName, setCustomerName] = useState("")
  const [customerPhone, setCustomerPhone] = useState("")
  const [customerEmail, setCustomerEmail] = useState("")
  const [subtotal, setSubtotal] = useState(0)
  const [showNameError, setShowNameError] = useState(false)
  const { cartItems, clearCart } = useCart()
  const navigate = useNavigate()

  useEffect(() => {
    const storedSubtotal = localStorage.getItem("cartSubtotal")
    if (storedSubtotal) {
      setSubtotal(parseFloat(storedSubtotal))
    }
  }, [])

  const handleNameChange = (event) => {
    setCustomerName(event.target.value)
    if (event.target.value.trim()) setShowNameError(false)
  }

  const handleNameBlur = () => {
    if (!customerName.trim()) {
      setShowNameError(true)
    }
  }

  const handlePhoneChange = (event) => {
    setCustomerPhone(event.target.value)
  }

  const handleEmailChange = (event) => {
    setCustomerEmail(event.target.value)
  }

  const validateForm = () => {
    if (!customerName.trim()) {
      setShowNameError(true)
      return false
    }
    return true
  }

  const handlePayment = async (metodoPago) => {
    if (!validateForm()) return

    console.log("Método de pago:", metodoPago) // Verificar el valor de metodoPago

    const ventasCollection = collection(db, "Ventas")
    const clientesCollection = collection(db, "Clientes")

    try {
      // Calcular el total dependiendo del método de pago
      const total = metodoPago === "Efectivo" ? subtotal : subtotal * 1.03
      console.log("Subtotal:", subtotal)
      console.log("Total calculado:", total)

      // Add a new document to the Ventas collection
      const docRef = await addDoc(ventasCollection, {
        customerName,
        customerPhone,
        customerEmail,
        items: cartItems.map((item) => ({
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
        })),
        total,
        estado: "Por pagar",
        metodoPago,
        fecha: new Date(),
        activo: true,
      })

      // Agregar el campo id con el ID del documento
      await updateDoc(doc(db, "Ventas", docRef.id), {
        id: docRef.id,
      })

      console.log("Venta registrada con ID: ", docRef.id)

      // Check if the customer already exists in the Clientes collection
      const existingCustomerQuery = query(
        clientesCollection,
        where("telefono", "==", customerPhone)
      )
      const existingCustomerSnapshot = await getDocs(existingCustomerQuery)

      if (existingCustomerSnapshot.empty) {
        // Add a new document to the Clientes collection if the customer doesn't already exist
        await addDoc(clientesCollection, {
          nombre: customerName,
          telefono: customerPhone,
          email: customerEmail,
        })
        console.log("Nuevo cliente añadido")
      } else {
        console.log("Cliente ya existente")
      }

      clearCart()
      navigate(`/ventas?venta=${docRef.id}`) // Navegar a pedidos con el ID de la venta
    } catch (e) {
      console.error("Error al crear la venta: ", e)
    }
  }

  return (
    <>
      <Header />
      <div className="checkout-wrapper">
        <div className="checkout-content">
          <div className="checkout-header">
            <h2>Checkout</h2>
          </div>
          <div className="subtotal">
            <p>
              Subtotal:{" "}
              <span className="total">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(subtotal)}
              </span>
            </p>
          </div>
          <div className="customer-info">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Nombre Cliente"
                value={customerName}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                className={showNameError ? "input-error" : ""}
              />
              {showNameError && (
                <p className="error-message show">El nombre es obligatorio.</p>
              )}
            </div>
            <input
              type="text"
              placeholder="Celular"
              value={customerPhone}
              onChange={handlePhoneChange}
            />
            <input
              type="email"
              placeholder="Email (Opcional)"
              value={customerEmail}
              onChange={handleEmailChange}
            />
          </div>
          <div className="payment-methods">
            <button onClick={() => handlePayment("Efectivo")}>
              Pago en Efectivo
            </button>
            <button onClick={() => handlePayment("Tarjeta de credito")}>
              Pago con Tarjeta
            </button>
          </div>
          <div className="checkout-footer">
            <table>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    <td>${item.price.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout
