import React, { useState } from "react"
import QuantitySelector from "./quantitySelector"
import TrashIcon from "../svg-components/trashIcon"
import { useCart } from "../context/cartContext"

const SidebarProduct = ({ item, removeFromCart }) => {
  const { updateQuantity } = useCart()
  const [quantity, setQuantity] = useState(item.quantity) // State to track the quantity

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity) // Update the quantity state
    if (newQuantity > 0) {
      updateQuantity(item.id, newQuantity) // Update the cart if the item is already there
    }
  }

  return (
    <div key={item.name} className="cart-item">
      <div className="box-container">
        <span>{item.name}</span>
      </div>
      <div className="cart-item-quantity-remove">
        <QuantitySelector
          initialCount={item.quantity}
          onQuantityChange={handleQuantityChange}
          css="quantity-selector sidebar-quantity"
        />
        <button
          onClick={() => removeFromCart(item.id)}
          className="remove-button"
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  )
}

export default SidebarProduct
