const TrashIcon = () => {
  return (
    <svg
      width="57"
      height="46"
      viewBox="0 0 57 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_10_162)">
        <rect
          x="6.6"
          y="4.6"
          width="43.2"
          height="32.4"
          rx="13.8"
          fill="black"
          stroke="white"
          stroke-width="1.2"
        />
        <rect x="19.2" y="14.8" width="19.2" height="1.2" fill="white" />
        <rect x="24" y="28" width="9.6" height="1.2" fill="white" />
        <path
          d="M23.9999 29.2L21.5999 16H22.7999L25.1999 29.2H23.9999Z"
          fill="white"
        />
        <path d="M33.6 29.2L36 16H34.8L32.4 29.2H33.6Z" fill="white" />
        <rect x="25.2" y="11.2" width="7.2" height="2.4" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_10_162"
          x="0"
          y="0"
          width="56.3999"
          height="45.6"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10_162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10_162"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
export default TrashIcon
