import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useQuery, gql } from "@apollo/client"
import styles from "../styles/ventaPopupStyles.module.css"

const { db } = require("../firebaseConfig")
const { doc, updateDoc } = require("firebase/firestore")

const GET_DATA_QUERY = gql`
  query {
    allProduct {
      _id
      title
      price
    }
  }
`

const VentaPopup = ({ venta, onClose, onUpdatePayment }) => {
  const [amountPaid, setAmountPaid] = useState("")
  const [change, setChange] = useState(0)
  const [total, setTotal] = useState(venta.total)
  const [selectedProducts, setSelectedProducts] = useState(
    venta.items.map((item) => ({
      ...item,
      title: item.name,
      _id: item.id,
      uniqueKey: crypto.randomUUID(),
    })) || []
  )

  const [selectedProduct, setSelectedProduct] = useState("")
  const [quantity, setQuantity] = useState(1)
  const { loading, error, data } = useQuery(GET_DATA_QUERY)

  useEffect(() => {
    setTotal(venta.total)
  }, [venta])

  const handleAmountChange = (e) => {
    let value = e.target.value
    if (value.startsWith("0") && value.length > 1 && !value.startsWith("0.")) {
      value = value.replace(/^0+/, "")
    }
    const amount = parseFloat(value) || 0
    setAmountPaid(value)
    setChange(amount - total)
  }

  const handlePayment = async () => {
    const amount = parseFloat(amountPaid) || 0
    onUpdatePayment(venta.id, amount, change)

    const itemsToUpdate = selectedProducts.map((product) => {
      const { _id, title, quantity, price } = product
      if (!_id || !title || !quantity || !price) {
        console.error("Producto con datos incompletos:", product)
        throw new Error("Producto con datos incompletos")
      }
      return {
        id: _id,
        name: title,
        quantity,
        price,
      }
    })

    try {
      const ventaRef = doc(db, "Ventas", venta.id)
      console.log("Actualizando la venta...: ", venta.id)
      await updateDoc(ventaRef, {
        items: itemsToUpdate,
        total,
        estado: "Pagado",
      })
      console.log("Venta actualizada con éxito")
    } catch (error) {
      console.error("Error actualizando la venta: ", error)
    }

    onClose()
  }

  const handleClickOutside = (e) => {
    if (e.target.classList.contains(styles.ventaPopup)) {
      onClose()
    }
  }

  const addProduct = () => {
    const product = data.allProduct.find((p) => p._id === selectedProduct)
    if (product) {
      const updatedProducts = [
        ...selectedProducts,
        { ...product, quantity, uniqueKey: crypto.randomUUID() },
      ]
      setSelectedProducts(updatedProducts)
      const newTotal = updatedProducts.reduce(
        (acc, product) => acc + product.price * product.quantity,
        0
      )
      setTotal(
        venta.metodoPago === "Tarjeta de credito" ? newTotal * 1.03 : newTotal
      )
      console.log("Producto agregado:", product)
      console.log("Nuevo total calculado:", newTotal)
    }
  }

  const updateQuantity = (uniqueKey, newQuantity) => {
    const quantity = newQuantity === "" ? 0 : parseInt(newQuantity, 10)
    const updatedProducts = selectedProducts.map((product) => {
      if (product.uniqueKey === uniqueKey) {
        return { ...product, quantity }
      }
      return product
    })
    setSelectedProducts(updatedProducts)
    const newTotal = updatedProducts.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    )
    setTotal(
      venta.metodoPago === "Tarjeta de credito" ? newTotal * 1.03 : newTotal
    )
    console.log("Cantidad actualizada para:", uniqueKey)
    console.log("Nuevo total calculado:", newTotal)
  }

  const removeProduct = (uniqueKey) => {
    const updatedProducts = selectedProducts.filter(
      (p) => p.uniqueKey !== uniqueKey
    )
    setSelectedProducts(updatedProducts)
    const newTotal = updatedProducts.reduce(
      (acc, product) => acc + product.price * product.quantity,
      0
    )
    setTotal(
      venta.metodoPago === "Tarjeta de credito" ? newTotal * 1.03 : newTotal
    )
    console.log("Producto eliminado:", uniqueKey)
    console.log("Nuevo total calculado:", newTotal)
  }

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value)
  }

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value, 10) || 1)
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <motion.div
      className={styles.ventaPopup}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className={styles.ventaPopupContent}>
        <h2>Detalles de la Venta</h2>
        <p>Cliente: {venta.customerName}</p>
        <p>
          Total:{" "}
          {total.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
          })}
        </p>
        <div className={styles.paymentDetails}>
          <label>
            Cantidad Pagada:
            <input
              type="text"
              value={amountPaid}
              onChange={handleAmountChange}
            />
          </label>
          <p>
            Cambio:{" "}
            {change.toLocaleString("es-MX", {
              style: "currency",
              currency: "MXN",
            })}
          </p>
        </div>
        <h3>Productos Seleccionados</h3>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Precio Total</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((product) => (
              <tr key={product.uniqueKey}>
                <td>{product.title}</td>
                <td>
                  <input
                    type="text"
                    className={styles.quantityInput}
                    value={product.quantity}
                    onChange={(e) =>
                      updateQuantity(product.uniqueKey, e.target.value)
                    }
                  />
                </td>
                <td>
                  {product.price !== null
                    ? (product.price * product.quantity).toLocaleString(
                        "es-MX",
                        {
                          style: "currency",
                          currency: "MXN",
                        }
                      )
                    : "N/A"}
                </td>
                <td>
                  <button onClick={() => removeProduct(product.uniqueKey)}>
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.addProductRow}>
          <div className={styles.tableActions}>
            <select value={selectedProduct} onChange={handleProductChange}>
              <option value="">Selecciona un producto</option>
              {data.allProduct.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.title} -{" "}
                  {product.price !== null
                    ? product.price.toLocaleString("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })
                    : "N/A"}
                </option>
              ))}
            </select>
            <select value={quantity} onChange={handleQuantityChange}>
              {[...Array(100).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            <button onClick={addProduct} className={styles.addButton}>
              Agregar Producto
            </button>
          </div>
        </div>
        <button onClick={handlePayment}>Confirmar Pago</button>
        <button className={styles.secondary} onClick={onClose}>
          Cerrar
        </button>
      </div>
    </motion.div>
  )
}

export default VentaPopup
