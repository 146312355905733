import React from "react"
import { ApolloProvider } from "@apollo/client"
import apolloClient from "./apolloClient"
import Productos from "./pages/productos"
import Checkout from "./pages/checkout"
import { CartProvider } from "./context/cartContext"
import Contacto from "./pages/contacto"
import Pedidos from "./pages/pedidos"
import Invoice from "./pages/Invoice"
import QRAbbas from "./pages/abbasQR"
import StockAdmin from "./pages/StockAdmin"
import { AuthProvider, useAuth } from "./AuthContext"
import SignIn from "./components/signin"
import { Routes, Route, Navigate } from "react-router-dom"

const ProtectedRoute = ({ element: Element, ...rest }) => {
  const { currentUser } = useAuth()
  return currentUser ? <Element {...rest} /> : <Navigate to="/signin" />
}

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <CartProvider>
          <Routes>
            <Route path="/" element={<ProtectedRoute element={Productos} />} />
            <Route
              path="/productos"
              element={<ProtectedRoute element={Productos} />}
            />
            <Route
              path="/clientes"
              element={<ProtectedRoute element={Contacto} />}
            />
            <Route
              path="/checkout"
              element={<ProtectedRoute element={Checkout} />}
            />
            <Route
              path="/ventas"
              element={<ProtectedRoute element={Pedidos} />}
            />
            <Route
              path="/invoice"
              element={<ProtectedRoute element={Invoice} />}
            />
            <Route
              path="/qr-abbas"
              element={<ProtectedRoute element={QRAbbas} />}
            />
            <Route
              path="/stock"
              element={<ProtectedRoute element={StockAdmin} />}
            />
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        </CartProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
