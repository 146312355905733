import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { auth } from "../firebaseConfig"
import { signInWithEmailAndPassword } from "firebase/auth"
const SignIn = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const signIn = async (e) => {
    e.preventDefault()
    try {
      await signInWithEmailAndPassword(auth, email, password)
      navigate("/")
    } catch (error) {
      setError(error.message)
    }
  }

  return (
    <div>
      <h1>Sign In</h1>
      <form onSubmit={signIn}>
        <input
          type="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <button type="submit">Sign In</button>
      </form>
      <Link to="/signup">Don't have an account? Sign Up</Link>
      {error && <p>{error}</p>}
    </div>
  )
}

export default SignIn
