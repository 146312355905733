import React from "react"
import { Link } from "react-router-dom"
import { useCart } from "../context/cartContext"
import PosLogo from "./svgLogo"
import styles from "../styles/header.module.css"

const Header = ({ toggleSidebar }) => {
  const { cartItems } = useCart()

  // Calculate the total number of items in the cart
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0)

  return (
    <header className="header">
      <nav className="nav">
        <Link to="/productos" className="nav-item">
          Productos
        </Link>
        <Link to="/clientes" className="nav-item">
          Clientes
        </Link>
        <Link to="/ventas" className="nav-item">
          Ventas
        </Link>
        <Link to="/stock" className="nav-item">
          Stock
        </Link>
      </nav>
      <button className="car-shop-button" onClick={toggleSidebar}>
        Carrito ({totalItems})
      </button>
    </header>
  )
}

export default Header
