import React, { useState } from "react"
import QuantitySelector from "../components/quantitySelector"
import "../styles/productCardStyles.css"
import { useCart } from "../context/cartContext"
import Underline from "./underline"
import { motion } from "framer-motion"

const ProductCard = ({
  name,
  price,
  id,
  image = "",
  category,
  variants,
  stock,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [selectedVariant, setSelectedVariant] = useState(
    variants && variants.length > 0 ? variants[0] : null
  )
  const { addToCart, updateQuantity } = useCart()

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible)
  }

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity)
    if (newQuantity > 0) {
      updateQuantity(id, newQuantity)
    }
  }

  const handleAddToCart = () => {
    if (quantity > 0) {
      const itemPrice = selectedVariant ? selectedVariant.price : price
      addToCart(name, itemPrice, quantity, id)
      setIsPopupVisible(false)
    } else {
      console.log("Please select a quantity before adding to cart")
    }
  }

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant)
  }

  function formatPrice(price) {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(price)
  }

  return (
    <div className="container" onClick={togglePopup} key={name}>
      <motion.div
        className="card"
        style={{
          boxShadow: category?.color2 + " 5px 5px 0px",
          backgroundColor: "#FAFAFA",
        }}
      >
        <div className="title">
          <h3>{name}</h3>
          <div className="underline">
            <Underline color={category?.color2} />
          </div>
        </div>
        <motion.div className="product-image">
          <img src={image || "default-image-path.jpg"} alt={name} />
        </motion.div>
        <div className="variant-selector">
          {variants &&
            variants.length > 0 &&
            variants.map((variant, index) => (
              <div
                key={index}
                className="variant-box"
                onClick={(e) => {
                  e.stopPropagation() // Stop the event from bubbling up to parent elements
                  handleVariantChange(variant)
                }}
                style={{
                  backgroundColor:
                    selectedVariant === variant ? category?.color2 : "#fff",
                  color: "#000",
                  border: `1px solid ${category?.color1}`,
                }}
              >
                {variant.title ? variant.title.charAt(0) : "?"}
              </div>
            ))}
        </div>
        <div className="info-card">
          <p className="price">
            {selectedVariant
              ? formatPrice(selectedVariant.price)
              : formatPrice(price)}
          </p>
          <p className="stock">Stock: {stock}</p> {/* Mostrar stock */}
        </div>
      </motion.div>

      {isPopupVisible && (
        <div className="popup" onClick={(e) => e.stopPropagation()}>
          <QuantitySelector
            initialCount={quantity}
            onQuantityChange={handleQuantityChange}
          />
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            Add to cart
          </button>
        </div>
      )}
    </div>
  )
}

export default ProductCard
