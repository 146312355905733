import React, { useState } from "react"
import "../styles/quantitySelectorStyle.css"

const QuantitySelector = ({
  initialCount,
  onQuantityChange,
  css = "quantity-selector",
}) => {
  const [count, setCount] = useState(initialCount)

  const handleIncrement = (e) => {
    e.stopPropagation()
    const newCount = count + 1
    setCount(newCount)
    onQuantityChange(newCount) // Invoke the callback with the new count
  }

  const handleDecrement = (e) => {
    e.stopPropagation()
    const newCount = count > 0 ? count - 1 : 0
    setCount(newCount)
    onQuantityChange(newCount) // Invoke the callback with the new count
  }

  const handleChange = (event) => {
    event.stopPropagation()
    const value = parseInt(event.target.value, 10)
    const newCount = isNaN(value) ? 0 : value
    setCount(newCount)
    onQuantityChange(newCount) // Invoke the callback with the new count
  }

  return (
    <div className={css} onClick={(e) => e.stopPropagation()}>
      <button onClick={handleDecrement}>-</button>
      <input type="text" value={count} onChange={handleChange} />
      <button onClick={handleIncrement}>+</button>
    </div>
  )
}

export default QuantitySelector
