import React, { useState, useEffect, useMemo } from "react"
import ProductCard from "../components/productCard"
import Filter from "../components/filter"
import Header from "../components/header"
import Sidebar from "../components/sidebar"
import { useQuery, gql } from "@apollo/client"
import SidebarToggleButton from "../components/SidebarToggleButtons"
const { db } = require("../firebaseConfig")
const { collection, getDocs } = require("firebase/firestore")

const GET_DATA_QUERY = gql`
  query {
    allProduct {
      _id
      title
      price
      size {
        title
      }
      tags {
        title
      }
      color {
        name
        hex
      }
      variants {
        title
        price
        size {
          height
          abbreviation
        }
        color {
          name
          hex
        }
        images {
          asset {
            url
          }
        }
        customization
      }
      customization
      images {
        asset {
          url
        }
      }
      category {
        title
        color1
        color2
        color3
      }
    }
  }
`

const Productos = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState("")
  const { loading, error, data } = useQuery(GET_DATA_QUERY)
  const [categories, setCategories] = useState([])
  const [showToggleButton, setShowToggleButton] = useState(false)
  const [firebaseStock, setFirebaseStock] = useState({})

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Inventario"))
        const stockData = {}
        querySnapshot.forEach((doc) => {
          const productData = doc.data()
          const sanityId = productData._id // Utilizar el campo _id del documento en Firebase
          stockData[sanityId] = productData.stock
        })
        setFirebaseStock(stockData)
      } catch (error) {
        console.error("Error fetching stock data: ", error)
      }
    }

    fetchStock()
  }, [])

  const productsByCategories = useMemo(() => {
    return (
      data?.allProduct
        ?.filter((product) => product.category)
        .map((product) => ({
          title: product.category?.title || "Unknown",
          color1: product.category?.color1,
          color2: product.category?.color2,
          color3: product.category?.color3,
        }))
        .filter(
          (category, index, self) =>
            index === self.findIndex((cat) => cat.title === category.title)
        ) || []
    )
  }, [data])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setShowToggleButton(scrollTop > 100)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    if (data) {
      const extractedCategories = [
        ...new Set(
          data.allProduct.map((product) => product.category?.title || "Unknown")
        ),
      ]
      setCategories(extractedCategories.map((title) => ({ title })))
    }
  }, [data])

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen)
  }

  const handleCategoryChange = (category) => {
    setSelectedCategory(category)
  }

  const filteredProducts = useMemo(() => {
    let products = data?.allProduct || []

    if (selectedCategory) {
      products = products.filter(
        (product) => product.category?.title === selectedCategory
      )
    }

    const sortedProducts = [...products]

    sortedProducts.sort((a, b) => {
      const categoryA = a.category?.title || ""
      const categoryB = b.category?.title || ""
      return categoryA.localeCompare(categoryB)
    })

    return sortedProducts
  }, [data, selectedCategory])

  const getImageUrl = (product) => {
    const variantImageUrl = product.variants?.[0]?.images?.[0]?.asset?.url
    if (variantImageUrl) return variantImageUrl
    return product.images?.[0]?.asset?.url || "default-image-path.jpg"
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} onClose={() => setSidebarOpen(false)} />
      <Filter
        categories={productsByCategories}
        onCategoryChange={handleCategoryChange}
        selectedCategory={selectedCategory}
      />
      <div className="products-grid">
        {filteredProducts?.map((product) => (
          <ProductCard
            name={product.title}
            price={product.price}
            key={product._id}
            id={product._id}
            image={getImageUrl(product)}
            variants={product.variants}
            category={product.category}
            stock={firebaseStock[product._id]} // Obtener el stock de Firebase
          />
        ))}
      </div>
      <SidebarToggleButton
        onClick={toggleSidebar}
        showToggleButton={showToggleButton}
      />
    </div>
  )
}

export default Productos
