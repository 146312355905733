import React, { createContext, useState, useContext, useEffect } from "react"

const CartContext = createContext()

export const useCart = () => useContext(CartContext)

export const CartProvider = ({ children }) => {
  // Initialize cartItems from localStorage if available
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems")
    return savedCartItems ? JSON.parse(savedCartItems) : []
  })

  // Effect to update localStorage when cartItems changes
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems))
  }, [cartItems])

  const addToCart = (name, price, quantity, id) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex > -1) {
        const updatedItems = [...currentItems]
        updatedItems[itemIndex].quantity += quantity
        return updatedItems
      } else {
        return [...currentItems, { name, price, quantity, id }]
      }
    })
  }

  const updateQuantity = (id, quantity) => {
    setCartItems((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.id === id)
      if (itemIndex > -1 && quantity > 0) {
        const updatedItems = [...currentItems]
        updatedItems[itemIndex].quantity = quantity
        return updatedItems
      } else if (itemIndex > -1 && quantity === 0) {
        return currentItems.filter((item) => item.id !== id)
      }
      return currentItems
    })
  }

  const removeFromCart = (productId) => {
    setCartItems((currentItems) =>
      currentItems.filter((item) => item.id !== productId)
    )
  }

  const getSubtotal = () => {
    return cartItems.reduce((total, currentItem) => {
      return total + currentItem.price * currentItem.quantity
    }, 0)
  }

  const clearCart = () => {
    setCartItems([])
  }

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateQuantity,
        removeFromCart,
        clearCart,
        getSubtotal,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
