import { motion } from "framer-motion"
import { useCart } from "../context/cartContext"
import { Link } from "react-router-dom"
import SidebarProduct from "./sidebarProduct"
import SixSagLine from "../svg-components/sixsagLine"
import "../styles/sidebarStyle.css"

const sidebarVariants = {
  open: { x: 0 },
  closed: { x: "100%" },
}

const Sidebar = ({ isOpen, onClose }) => {
  const { cartItems, removeFromCart, getSubtotal } = useCart()

  const handleCheckout = () => {
    const subtotal = getSubtotal()
    localStorage.setItem("cartSubtotal", subtotal.toString()) // Save subtotal to local storage
    onClose() // Close the sidebar
    // Assuming navigation is handled by the <Link> component
  }

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      exit="closed"
      variants={sidebarVariants}
      className="sidebar"
    >
      <div className="cart-header">
        <button onClick={onClose} className="close-button">
          {">>"}
        </button>
        <div className="subtotal-sidebar">
          <span className="subtotal">Subtotal</span>
          <span className="total">
            {new Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(getSubtotal())}
          </span>
          <SixSagLine />
          <Link to="/checkout">
            <button onClick={handleCheckout} className="checkout-button">
              Checkout
            </button>
          </Link>
        </div>
      </div>
      {cartItems.map((item) => (
        <SidebarProduct
          key={`${item.id}-${item.quantity}`}
          item={item}
          removeFromCart={removeFromCart}
        />
      ))}
    </motion.div>
  )
}

export default Sidebar
