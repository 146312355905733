import React from "react"
import "../styles/sidebarToggleButton.css"
import CartSvg from "./svgCart"

const SidebarToggleButton = ({ onClick, showToggleButton }) => {
  return (
    <button
      className={`sidebar-toggle-button ${showToggleButton ? "show" : ""}`}
      onClick={onClick}
    >
      <CartSvg />
    </button>
  )
}

export default SidebarToggleButton
