const SixSagLine = () => {
  return (
    <svg
      width="100"
      height="15"
      viewBox="0 0 128 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.78617C8.1159 6.5398 14.505 12.1319 25.083 12.3558C35.661 12.5796 38.6978 6.16293 48.6604 5.75251C58.6229 5.34208 59.0089 10.9946 68.0868 10.5842C77.1646 10.1737 79.6137 3.83165 87.8453 3.98089C96.0769 4.13014 95.0411 11.4523 103.619 11.2284C112.197 11.0045 117.602 2.60414 124.872 3.01456"
        stroke="url(#paint0_linear_10_171)"
        stroke-width="4"
        stroke-linecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10_171"
          x1="3"
          y1="3"
          x2="135"
          y2="3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEBB36" />
          <stop offset="1" stop-color="#ffa44e" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default SixSagLine
