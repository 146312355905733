import React, { useRef } from "react"
import html2canvas from "html2canvas"
import "../styles/InvoiceStyles.css"

const venta = {
  id: "HvDCAPDYAeOpMJKZnHJl",
  items: [
    {
      price: 350,
      quantity: 2,
      id: "552f5a37-4734-4aa0-bff0-76523eabea50",
      name: "Rosario San Benito",
    },
  ],
  fecha: {
    seconds: 1709223442,
    nanoseconds: 554000000,
  },
  customerPhone: "",
  total: 700,
  metodoPago: "Efectivo",
  estado: "Por pagar",
  customerName: "Carlos",
  customerEmail: "",
}

const InvoiceComponent = ({
  customerName = venta.customerName,
  items = venta.items,
  total = venta.total,
}) => {
  const invoiceRef = useRef(null)

  const handleDownloadImage = async () => {
    if (invoiceRef.current) {
      const canvas = await html2canvas(invoiceRef.current, {
        scale: 2, // Adjust scale as needed
        dpi: 144, // Adjust DPI as needed
      })
      const image = canvas.toDataURL("image/png")
      const link = document.createElement("a")
      link.href = image
      link.download = "invoice.png"
      link.click()
    }
  }

  return (
    <div className="wrapper-invoice" id="invoice">
      <div className="invoice" ref={invoiceRef}>
        <h1>Invoice</h1>
        <p>Customer Name: {customerName}</p>
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
            >{`${item.name} - $${item.price} x ${item.quantity}`}</li>
          ))}
        </ul>
        <p>Total: ${total}</p>
      </div>
      <button onClick={handleDownloadImage} className="capture-button">
        Download Invoice
      </button>
    </div>
  )
}

export default InvoiceComponent
