import { useState, useEffect } from "react"
import Header from "../components/header"
import styles from "../styles/StockAdmin.module.css" // Importa el CSS como módulo
import { gql, useQuery } from "@apollo/client"
const { db } = require("../firebaseConfig")
const { collection, getDocs, doc, updateDoc } = require("firebase/firestore")

const GET_PRODUCTS_QUERY = gql`
  query {
    allProduct {
      _id
      title
      images {
        asset {
          url
        }
      }
    }
  }
`

const StockAdmin = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS_QUERY)
  const [products, setProducts] = useState([])
  const [stockData, setStockData] = useState({})

  useEffect(() => {
    const fetchStock = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Inventario"))
        const stockData = {}
        querySnapshot.forEach((doc) => {
          const productData = doc.data()
          stockData[productData._id] = { ...productData, docId: doc.id } // Guardar el ID del documento de Firebase
        })

        setStockData(stockData)

        if (data && data.allProduct) {
          const combinedData = data.allProduct.map((product) => ({
            ...product,
            stock: stockData[product._id]?.stock || 0,
            variants: stockData[product._id]?.variants || {}, // Agregar variantes
            docId: stockData[product._id]?.docId || null, // Guardar el ID del documento de Firebase
          }))
          setProducts(combinedData)
        }
      } catch (error) {
        console.error("Error fetching stock data: ", error)
      }
    }

    if (data) {
      fetchStock()
    }
  }, [data])

  const handleStockChange = async (productId, variantKey, newStock) => {
    try {
      const product = products.find((product) => product._id === productId)
      if (!product || !product.docId) {
        throw new Error("Producto no encontrado o sin docId en Firebase.")
      }

      const productRef = doc(db, "Inventario", product.docId)

      if (variantKey) {
        const updatedVariants = {
          ...product.variants,
          [variantKey]: { stock: Number(newStock) },
        }

        await updateDoc(productRef, { variants: updatedVariants })

        setProducts((prevProducts) =>
          prevProducts.map((p) =>
            p._id === productId
              ? {
                  ...p,
                  variants: updatedVariants,
                }
              : p
          )
        )
      } else {
        await updateDoc(productRef, { stock: Number(newStock) })

        setProducts((prevProducts) =>
          prevProducts.map((p) =>
            p._id === productId ? { ...p, stock: Number(newStock) } : p
          )
        )
      }
    } catch (error) {
      console.error("Error updating stock: ", error)
    }
  }

  const getImageUrl = (product) => {
    console.log("PRODUCTO: ", product)
    return product.images?.[0]?.asset?.url || "default-image-path.jpg" // Asegurarse de que hay una ruta predeterminada
  }

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  return (
    <div className={styles.container}>
      <Header />

      <h1>Stock Administration</h1>
      <div className={styles.productsGrid}>
        {products.map((product) => (
          <div className={styles.productCard} key={product._id}>
            <img src={getImageUrl(product)} alt={product.title} />
            <h3>{product.title}</h3>
            {Object.keys(product.variants).length > 0 ? (
              Object.entries(product.variants).map(
                ([variantKey, variantData]) => (
                  <div key={variantKey}>
                    <p>{variantKey}</p>
                    <p>
                      Stock:
                      <input
                        type="number"
                        value={variantData.stock}
                        onChange={(e) =>
                          handleStockChange(
                            product._id,
                            variantKey,
                            e.target.value
                          )
                        }
                      />
                    </p>
                  </div>
                )
              )
            ) : (
              <p>
                Stock:
                <input
                  type="number"
                  value={product.stock}
                  onChange={(e) =>
                    handleStockChange(product._id, null, e.target.value)
                  }
                />
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default StockAdmin
