// In Filter.js
import React, { useRef, useEffect } from "react"
import "../styles/filter.css"

const Filter = ({ categories, onCategoryChange, selectedCategory }) => {
  const filterRef = useRef(null)
  console.log("Filter.js: ")

  useEffect(() => {
    const filterElement = filterRef.current
    const handleWheel = (event) => {
      event.preventDefault()
      filterElement.scrollLeft += event.deltaY
    }

    filterElement.addEventListener("wheel", handleWheel)

    return () => {
      filterElement.removeEventListener("wheel", handleWheel)
    }
  }, [])

  return (
    <div className="filter-container">
      <div className="filter-header" ref={filterRef}>
        <div
          className={`filter-option ${selectedCategory === "" ? "active" : ""}`}
          onClick={() => onCategoryChange("")}
          style={{
            backgroundColor: selectedCategory === "" ? "#007bff" : "#fff",
            color: selectedCategory === "" ? "#fff" : "#333",
          }}
        >
          All
        </div>
        {categories.map((category, index) => (
          <div
            key={index}
            className={`filter-option ${
              selectedCategory === category.title ? "active" : ""
            }`}
            onClick={() => onCategoryChange(category.title)}
            style={{
              backgroundColor:
                selectedCategory === category.title ? category.color2 : "#fff",
              color:
                selectedCategory === category.title ? category.color1 : "#333",
            }}
          >
            {category.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Filter
