// apolloClient.js
import { ApolloClient, InMemoryCache } from "@apollo/client"

const projectId = "k5y2wbhe"
const dataset = "production"
const tag = "default" // Use 'default' or another tag if you have set up a specific tagged endpoint

const sanityEndpoint = `https://${projectId}.api.sanity.io/v1/graphql/${dataset}/${tag}`

const client = new ApolloClient({
  uri: sanityEndpoint, // Replace with your Sanity GraphQL endpoint
  cache: new InMemoryCache(),
  // Include headers here if you need to send authentication tokens
})

export default client
